function getNumberWithCommas(x: any, decimalPlaces?: number): any {
  if (isNaN(x)) {
    return x
  }
  let dec = decimalPlaces
  if (decimalPlaces === undefined) {
    dec = 0
  }
  return parseFloat(x)
    .toFixed(dec)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default getNumberWithCommas
